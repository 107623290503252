.icon-button {
  font-size: 0.8rem;
  cursor: pointer;
}

.icon-button-danger {
  @extend .icon-button;
  color: $red;
}

.form-control-feedback {
  color: red;
  font-size: 0.8rem;
}

.drop-container {
  background: #ffffff;
  border-radius: $border-radius;
  height: 100px;
  width: 100%;
  // box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #C0C4C7;
  margin: 10px 0;
}
.drop-container p {
  font-weight: 400;
  color: #C0C4C7;
  margin: 0;
}
.drop-container p .upload-button {
  display: inline-block;
  border: none;
  outline: none;
  cursor: pointer;
  color: #5754A3;
}
.drop-container p .upload-button input {
  display: none;
}

.nv-file-over {
  border-color: red;
}

.custom-file-input {
  cursor: pointer;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Dosya Seç";
}


.btn-fab{
  position: fixed;
  bottom: 35px;
  right: 15px;
  z-index: 500;
  padding-top: .78rem;
  padding-bottom: .78rem;
  padding-right: 1.2rem;
  padding-left: 1.2rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.fab-container {
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 500;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  > div {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 5px;

    button {
      margin-bottom: 17px;
    }
  }
}

.fab-toggler {
  float: right;
  z-index: 100;
}

.dropdown-edit-icon {
  cursor: pointer;
  color: #9E9E9E;
}

.app-edit-icon-color {
  color: #54AC58;
}

.app-edit-icon {
  @extend .app-edit-icon-color;
  cursor: pointer;
}

.app-delete-fa-icon {
  cursor: pointer;
  color: #d81010;
}


.auth-credit-card-logo {
  width: 2rem;
  margin-right: .2rem;
}


.date-icon-active {
  background: #2D65BA;
  border: 1px solid #1d43cc;
  color: whitesmoke;
}


.app-button-icon {
  margin-right: .68rem;
}


.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.82rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.cursor-pointer {
  cursor: pointer;
}

.close-modal-icon {
  cursor: pointer;
  font-size: 1.18rem;
  color: #6b6b6b;
}

.sidebar-user-info {
  color: #ffffff !important;
}

.sidebar-user-info .text-muted {
  color: #b1b1b1 !important;
}

.sidebar-blue {
  color: #79b5f5 !important;
}

.badge-company {
  font-size: 0.82rem !important;
  padding: 0.4em;
}

.nav-header-item {
  margin-top: 16px;
  padding-left: 16px;
  color: #808080;
  font-weight: bold;
}

a {
  color: #4a9aee;
}
a:hover {
  color: #2f84ea;
}

.success-plus-icon {
  cursor: pointer;
  font-size: 1.08rem;
  color: #4daf4f;
}


.swal2-container.swal2-backdrop-show {
  background: rgba(0,0,0,.4);
}

.my-swal {
  background: rgba(255,255,255,.8);
  backdrop-filter: blur(2px);
}

.caret-off .dropdown-toggle:after {
  display: none;
}

.mr2px {
  margin-right: 2px;
}

.mt2px {
  margin-top: 2px;
}

.main-footer {
  z-index: 998 !important;
  // @media (max-width: 768px) {
  //   min-height: 0.6rem !important;
  //   max-height: 24px;
  // }
}

.doc-color {
  color: #1e70b7;
}

.docf-icon {
  width: 36px;
  height: 36px;
  padding-right: 6px;
}

.docf-ops {
  flex: 1;
  min-width: 0;
  a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}


.swal2-container {
  .swal2-content {
    font-size: 1rem !important;
  }
}
