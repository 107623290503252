@import "scss/mixins/material-palette";
@import "scss/mixins/skinning";
@import "scss/variables";
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "scss/theming";
@import "scss/custom-bootstrap";
@import "scss/core";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "../fonts/simple-line-icons/simple-line-icons.css";
@import "./scss/app-specific";
@import "./scss/ios_font_fix";
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material';
@import '~@swimlane/ngx-datatable/assets/icons.css';

.ngx-datatable.material {
  box-shadow: none;

  .loading-row {
    height: 2px;
    padding: 0 0 0 0;
    margin: 0;
  }

  .datatable-body {

    .progress-linear{
      height: 2px;

      .container {
        height: 2px;
        .bar {
          height: 2px;
        }
      }
    }


  }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: transparent;
}
