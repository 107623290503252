/* Layout for search container */
/* Inspiration https://tympanus.net/codrops/2017/02/08/inspiration-search-ui-effects/ */
.search {
  overflow: hidden;
  width: 100hw;
  height: 100vh;
  background: rgba(white, .95);

  .modal-dialog {
    margin: 0;
    max-width: 100%;
    width: 100%;
    height: 100%;
  }

  .modal-content {
    background: transparent;
    border: 0;
    padding: 3rem;
    width: 100%;
    height: 100%;
  }
}

.search-close {
  position: fixed;
  z-index: 9000;
  top: 19px;
  right: 18px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: none;
  background: none;
  line-height: 1;
}

.search-input {
  font-family: inherit;
  font-size: 6vw;
  line-height: 1;
  display: inline-block;
  box-sizing: border-box;
  width: 50%;
  padding: 0.05rem 0;
  color: $black;
  border: 0;
  border-bottom: 2px solid;
  background: transparent;
  border-radius: 0;
  -webkit-appearance: none;

  @include hover-focus {
    cursor: pointer;
    text-decoration: none;
    outline: 0;
    border-color: theme-colors("primary");
  }

  &::-webkit-input-placeholder {
    color: $text-muted;
  }

  &::-moz-placeholder {
    opacity: 1;
    color: $text-muted;
  }

  &:-ms-input-placeholder {
    color: $text-muted;
  }

  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  &::-ms-clear {
    display: none;
  }
}

.search-suggestions {
  width: 35%;
  padding: 4rem 0 0 0;
  pointer-events: none;
}
