@mixin text-emphasis-variant($parent, $color, $ignore-warning: false) {
  #{$parent} {
    color: $color !important;
  }
  @if $emphasized-link-hover-darken-percentage != 0 {
    a#{$parent} {
      @include hover-focus() {
        color: darken($color, $emphasized-link-hover-darken-percentage) !important;
      }
    }
  }
}

@import "bootstrap/accordion";
@import "bootstrap/buttons";
@import "bootstrap/card";
@import "bootstrap/datepicker";
@import "bootstrap/forms";
@import "bootstrap/tables";
@import "bootstrap/type";
